import { Injectable } from '@angular/core';

/***
 * Sense paraules, representa la versió actual de l'aplicació
 */
@Injectable({
    providedIn: 'root'
})
export class VersionService {
    //primer codi --> nous plugins = actualització app store
    //segon codi --> canvis d'endpoints/opcions que afecten a mobile = actualitzacio de ionic
    //hardman.app.version
    //A
    public static readonly version: string = '7.1.2024.11.06.22.00';

    public getVersion(): string {
        return VersionService.version;
    }
}
