<div *ngIf="route">
    <shared-route-partner-selector class="small block" [routeId]="route.routeId" [readonly]="readonly"
        (valueChanged)="reloadRoute()"></shared-route-partner-selector>

    <shared-driverplate *ngIf="!route.isChildOfGrouped && !route.isDivided && showAssignDriverAndPlates"
        class="small block" [route]="route" [showCarrierCard]="false"
        [readonly]="readonly || (!readonly && !(canAssignDriverAndPlates || hascarrier))" (routeUpdated)="onValueChange($event)">
    </shared-driverplate>

    <shared-route-changetags [route]="route" class="small block" [showalltags]="true" [readonly]="readonly"
        (valueChange)="onValueChange($event)" (routeUpdated)="onRouteUpdated($event)">
    </shared-route-changetags>

    <div class="grup">
        <table class="hardman" style="width:100%">
            <tr>
                <th class="header blank"></th>
                <th class="header">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.THEORETICAL' | translate}}</th>
                <th class="header">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.REAL' | translate}}</th>
                <th class="header">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.DEVIATION' | translate}}</th>
            </tr>
            <tr>
                <th class="header">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.DISTANCE' | translate}}</th>
                <td class="right">{{route.theoreticalDistance | price}}</td>
                <td class="right">{{route.realDistance | price}}</td>
                <td class="right"></td>
            </tr>
            <tr>
                <th class="header">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.DURATION' | translate}}</th>
                <td class="right">{{route.theoreticalDuration | time}}</td>
                <td class="right">{{route.realDuration | time}}</td>
                <td class="right"></td>
            </tr>
        </table>
    </div>

    <boolean-component class="col-x divisible" *ngIf="route.canShowIsDivided" [(ngModel)]="route.isDivisible"
        [readonly]="false" placeholder="{{'ROUTE.ISDIVISIBLE.CAPTION' | translate}}" name="isDivisible"
        (valueChanged)="changeActiveIsDivisible()" [showHelpPoint]=true
        helpresource="{{'ROUTE.ISDIVISIBLE.HELP' | translate}}">
    </boolean-component>

    <shared-route-item-edit-additionaldata [route]="route" class="small block" (valueChange)="reloadRoute()">
    </shared-route-item-edit-additionaldata>

    <button-link *ngIf="route.isChildOfGrouped" variant="action" class="line left" (linkClick)="onGoParentGrouped()">{{
        'SHARED.ROUTE.ITEM.GOPARENTGROUPED.FORWARD.CAPTION' | translate}}</button-link>
</div>